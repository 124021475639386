<template>
  <b-card>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        type="grow"
        variant="warning"
        class="mr-1"
      />
      <span class="loading-text">Loading...</span>
    </div>
    <b-table
      v-else
      responsive
      bordered
      :items="list"
      :fields="fields"
    >
      <template #cell(client_id)="data">
        <span class="mw-100">{{ data.item.client_id }}</span>
      </template>
      <template #cell(recipient)="data">
        <span class="mw-200">{{ data.item.recipient }}</span>
      </template>
      <template #cell(package_amount)="data">
        <span class="mw-70">{{ data.item.package_amount }}</span>
      </template>
      <template #cell(image_url)="data">
        <viewer>
          <img
            :src="data.item.image_url ? data.item.image_url : require('../../assets/images/logo/default.png')"
            alt=""
            class="img"
          >
        </viewer>
      </template>
      <template #cell(status)="data">
        <span class="mw-220">{{ data.item.status }}</span>
      </template>
      <template #cell(update_time)="data">
        <span class="mw-200">{{ data.item.update_time }}</span>
      </template>
      <template #cell(total_chargeable_weight)="data">
        <span class="mw-140">{{ Number(data.item.total_chargeable_weight).toFixed(2) }}</span>
      </template>
      <template #cell(total_price)="data">
        <span class="mw-100">AU${{ Number(data.item.total_price).toFixed(2) }}</span>
      </template>
      <template #cell(is_belong)="data">
        <span class="mw-140">{{ data.item.is_belong ? '是' : '否' }}</span>
      </template>
      <!-- <template #cell(action)="data">
        <div class="mw-100">
          <span
            v-if="data.item.status === '货物已到代理点，等待取货'"
            class="watch"
            @click="handleConfirm(data.item)"
          >标记已取件</span>
        </div>
      </template> -->
    </b-table>
  </b-card>
</template>

<script>
import {
  BTable, BCard,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BCard,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      list: [],
      isLoading: false,
      fields: [
        { key: 'shipment_no', label: '运单ID' },
        { key: 'package_amount', label: '包裹数量' },
        { key: 'total_chargeable_weight', label: '计费重量(Kg)' },
        { key: 'total_price', label: '预估收益' },
        { key: 'is_belong', label: '是否自提点运单' },
        { key: 'image_url', label: '照片' },
        { key: 'status', label: '当前状态' },
        { key: 'update_time', label: '更新日期' },
      ],
    }
  },
  created() {
    this.handleInit()
  },
  methods: {
    async handleInit() {
      try {
        let status
        let year_month
        if (this.$route.params.id.indexOf('-') > 1) {
          status = 'history'
          year_month = this.$route.params.id
        } else {
          status = this.$route.params.id
          year_month = ''
        }
        this.isLoading = true
        const { data } = await this.$api.statisticPickupShipments({
          status,
          year_month,
        })
        this.isLoading = false
        this.list = data
        if (status !== 'history') {
          this.fields = this.fields.filter(item => item.key !== 'is_belong' && item.key !== 'total_chargeable_weight' && item.key !== 'total_price')
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleConfirm(item) {
      try {
        const data = await this.$api.confirmPickup({ shipment_id: item.shipment_id })
        if (data.code === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: '变更状态成功',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text{
    font-size:18px
}
.img{
  width: 60px;
  height: 60px;
}
.watch{
  cursor: pointer;
  font-size: 18px;
  display: block;
  min-width: 90px;
  color: #FFAF2A;
}
.mw-220{
  display: block;
  min-width: 220px
}
.mw-200{
  display: block;
  min-width: 200px
}
.mw-140{
  display: block;
  min-width: 140px
}
.mw-100{
  display: block;
  min-width: 100px;
}
.mw-70{
  display: block;
  min-width: 80px;
}
.mw-50{
  min-width: 50px;
}
.loading-text{
  font-size: 26px;
}
</style>
